import React from 'react';
import BlogPost from "../../../components/blogpost";
import { Link } from 'react-router-dom';
import '../../home/template.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import ImageItaly from  "../../../assets/images/blog/volpie-drums.webp";

function App({ mode="full" }) {
    function getHTML (mode)
    {
        switch (mode) {
            case "summary":
                return (
                    <section>
                        <h3><Link to='/blog/italy' title='Italy Blog'>Churchill Fellowship - Part 4</Link></h3>
                        <h4>The veg tanneries of Italy along with a tannery manufacturer and more</h4>
                        <p>
                            Finally a week visiting some of the famous veg tanneries of
                            the <Link to='https://www.pellealvegetale.it/en/consortium/' title='Genuine Italian Vegetable-Tanned Leather Consortium' target='_blank'>Genuine Italian Vegetable-Tanned Leather Consortium</Link>
                        </p>
                        <ul translate="no">
                            <li><Link to='https://www.laperlaazzurra.com' title='Conceria La Perla Azzurra' target='_blank'>Conceria La Perla Azzurra</Link></li>
                            <li><Link to='https://www.volpiconcerie.it/?lang=en' title='Volpi Concerie' target='_blank'>Volpi Concerie</Link></li>
                            <li><Link to='https://www.conceriailponte.it' title='Conceria Il Ponte' target='_blank'>Conceria Il Ponte</Link></li>
                            <li><Link to='https://www.lostivale.it' title='Lo Stivale' target='_blank'>Lo Stivale</Link></li>
                        </ul>
                        <p>
                            Plus the <Link to='http://www.laquerce.it/en/' title='La Querce' target='_blank' translate="no">La Querce</Link> and <Link to='https://www.conceriamaryam.it/en' title='Maryam S.r.l.' target='_blank' translate="no">Maryam S.r.l.</Link> tanneries, 
                            the <Link to='https://consorzioconciatori.it/en/ricerca-e-formazione-continua/po-te-co/' title='PO.TE.CO.' target='_blank' translate="no">PO.TE.CO.</Link> tanning 
                            technology centre, <Link to='https://ilpumasrl.it/' title='Il Puma' target='_blank'>Il Puma (finishers)</Link> and the tannery 
                            manufacturer: <Link to='https://tannery.italprogetti.it/en/' title='Italprogetti' target='_blank' translate="no">Italprogetti</Link>
                        </p>
                        <div className='bloglinks'>
                            <Link to='/blog/Italy' title='Italy Blog' className='linkImage'><img src={ImageItaly} alt='Italy Blog'></img></Link>
                            <Link to='/blog/Italy' title='Italy Blog' className='linkArrow'><span>View</span> <FontAwesomeIcon icon={faArrowRight} /></Link>
                        </div>
                    </section>
                )
            case "full":
            default:
                return (
                    <section>
                    <BlogPost id={"37"}/>
                    <BlogPost id={"38"}/>
                    <BlogPost id={"39"}/>
                    <BlogPost id={"40"}/>
                    <BlogPost id={"41"}/>
                    <BlogPost id={"42"}/>
                    <BlogPost id={"43"}/>
                    <BlogPost id={"44"}/>
                    <BlogPost id={"45"}/>
                </section>
                )
        }
    }
        
    return (
        getHTML(mode)
    )
}

export default App;