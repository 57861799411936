import React from 'react';
import BlogPost from "../../../components/blogpost";
import { Link } from 'react-router-dom';
import '../../home/template.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import ImageLineapelle from "../../../assets/images/blog/lineapelle.webp";

function App({ mode="full" }) {
    function getHTML (mode)
    {
        switch (mode) {
            case "summary":
                return (
                    <section>
                        <h3><Link to='/blog/Lineapelle' title='Lineapelle Blog'>Churchill Fellowship - Part 2</Link></h3>
                        <h4>Lineapelle, the International Leather Trade Fair and the international exhibition of machines and technologies</h4>
                        <p>
                            I visited the <Link to='https://www.lineapelle-fair.it/en' title='Lineapelle' target='_blank'>Lineapelle</Link> the international 
                            exhibition of leather, accessories, components, fabrics, synthetics and models in Milan. The international exhibition of machines and 
                            technologies for footwear, leathergoods and tanning industry (Simac Tanning Tech) runs alongside it.
                        </p>
                        <div className='bloglinks'>
                            <Link to='/blog/Lineapelle' title='Lineapelle Blog' className='linkImage'><img src={ImageLineapelle} alt='Lineapelle Blog'></img></Link>
                            <Link to='/blog/Lineapelle' title='Lineapelle Blog' className='linkArrow'><span>View</span> <FontAwesomeIcon icon={faArrowRight} /></Link>
                        </div>
                    </section>
                )
            case "full":
            default:
                return (
                    <section>
                    <BlogPost id={"29"}/>
                </section>
                )
        }
    }
        
    return (
        getHTML(mode)
    )
}

export default App;