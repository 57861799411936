import React from 'react';
import BlogPost from "../../../components/blogpost";
import { Link } from 'react-router-dom';
import '../../home/template.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import ImageKilgerPits from "../../../assets/images/blog/kilger-pits.webp";

function App({ mode="full" }) {
    function getHTML (mode)
    {
        switch (mode) {
            case "summary":
                return (
                    <section>                            
                        <h3><Link to='/blog/switzerland-germany' title='Switzerland / Germany Blog'>Churchill Fellowship - Part 3</Link></h3>
                        <h4>A week visiting some of the remaining tanneries in Switzerland and Germany.</h4>
                        <p>
                            I have managed to arrange visits to 4 tanneries. Three of the tanneries are well know veg tanneries but the 
                            first one is something different, a hair-on-hide tannery in Switzerland:
                         </p>
                         <ul>
                            <li translate="no"><Link to='https://www.neuenschwander.ch/' title='Neuenschwander' target='_blank'>Neuenschwander</Link></li>
                            <li translate="no"><Link to='https://www.lederfabrik-renz.de/' title='August Renz' target='_blank'>August Renz</Link></li>
                            <li translate="no">Gerberei Martin (<Link to='https://shoegazing.com/2021/11/04/report-gerberei-martin-traditional-oak-bark-sole-leather-tannery/' title='Nice article about Gerberei Martin' target='_blank'>article</Link> about the tannery)</li>
                            <li>A traditional tannery shut down 3 or 4 years ago</li>
                            <li translate="no"><Link to='https://kilger.de/en/' title='Kilger Tannery' target='_blank'>Kilger</Link></li>
                        </ul>
                        <div className='bloglinks'>
                            <Link to='/blog/switzerland-germany' title='Switzerland / Germany Blog' className='linkImage'><img src={ImageKilgerPits} alt='switzerland-germany Blog'></img></Link>
                            <Link to='/blog/switzerland-germany' title='Switzerland / Germany Blog' className='linkArrow'><span>View</span> <FontAwesomeIcon icon={faArrowRight} /></Link>
                        </div>
                    </section>

                    
                )
            case "full":
            default:
                return (
                    <section>
                        <BlogPost id={"32"}/>
                        <BlogPost id={"33"}/>
                        <BlogPost id={"34"}/>
                        <BlogPost id={"35"}/>
                        <BlogPost id={"36"}/>
                    </section>
                )
        }
    }
        
    return (
        getHTML(mode)
    )
}

export default App;