import React from 'react';
import { Hero } from "../../components/header";
import { Link } from 'react-router-dom';
import './template.css';

import ItalyPosts from "../blog/categories/italy";
import SwitzerlandGermanyPosts from "../blog/categories/switzerlandgermany";
import USPosts from "../blog/categories/us";
import UKPosts from "../blog/categories/uk";
import LineapellePosts from "../blog/categories/lineapelle";

import ImageLogo from "../../assets/images/churchill-fellowship-logo.webp";

function App() {
    const pageTitle = 'Churchill Fellowship';
    document.title = global.AppName + ' - ' + pageTitle;
    const logo = (
        <Link to='https://www.churchillfellowship.org/' title='Churchill Fellowship' target='_blank' className='image'>
            <img src={ImageLogo} alt='Churchill Fellowship logo'></img>
        </Link>
    );
    return (
        <>
            <Hero title={"Churchill Fellowship"} subtitle={"Travel Scholarship"} logo={logo}></Hero>

            {/* start main */}
            <div className="main_bg">
                <div className="wrap">
                    <div className="main" id="blogs">
                        <div>
                            <h2>Travel Blog</h2>
                            <p>
                                In 2023 I was awarded a <Link to='https://www.churchillfellowship.org/' title='Churchill Fellowship website' target='_blank'>Churchill Fellowship</Link> travel 
                                scholarship supported by the <Link to='https://www.royalcountrysidefund.org.uk/' title='Royal Countryside Fund website' target='_blank'>Royal Countryside Fund</Link>. 
                                The scholarship was to fund international travel to build his knowledge of tanning and to visit other tanneries abroad.
                            </p>
                            <p>
                                The travel was split into four parts:
                            </p>
                        </div>
                        <USPosts mode={"summary"} />
                        <LineapellePosts mode={"summary"} />
                        <SwitzerlandGermanyPosts mode={"summary"} />
                        <ItalyPosts mode={"summary"} />
                        <div className="clear"></div>
                        {/* <div>
                            <p>
                                The Churchill Fellowship is focused on international travel, and bringing back knowledge from abroad. That doesn't mean there isn't 
                                knowledged to be gained from within the UK. I have also been fortunate enough to have had the opportunity to visit other
                            </p>
                            <UKPosts mode={"summary"} />
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default App;