import React from 'react';
import { Hero } from "../../components/header";
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import '../home/template.css';

import ItalyPosts from "../blog/categories/italy";
import SwitzerlandGermanyPosts from "../blog/categories/switzerlandgermany";
import LineapellePosts from "../blog/categories/lineapelle";
import TanneryPosts from "../blog/categories/tannery";
import TanneryBuildPosts from "../blog/categories/tannerybuild";
import UKPosts from "../blog/categories/uk";
import USPosts from "../blog/categories/us";

function App() {
    const { category } = useParams();
    var tripTitle = category ? category : "summary";
    var postClass = category ? "blogposts" : "blogs";
    var pageTitle = tripTitle.replace(/-/g, " ") + " Blog";
    document.title = global.AppName + ' - ' + pageTitle;

    function selectTrip(tripTitle){
        switch(tripTitle.toLowerCase()) {
            case "italy":
                return (
                    <ItalyPosts mode={"full"} />
                )
            case "lineapelle":
                return (
                    <LineapellePosts mode={"full"} />
                )
            case "switzerland-germany":
                return (
                    <SwitzerlandGermanyPosts mode={"full"} />
                )
            case "tannery":
                return (
                    <TanneryPosts mode={"full"} />
                )
            case "tannery-build":
                return (
                    <TanneryBuildPosts mode={"full"} />
                )
            case "uk":
                return (
                    <UKPosts mode={"full"} />
                )
            case "us":
                return (
                    <USPosts mode={"full"} />
                )
            case "all":
                return (
                    <>
                        <USPosts mode={"full"} />
                        <LineapellePosts mode={"full"} />
                        <SwitzerlandGermanyPosts mode={"full"} />
                        <ItalyPosts mode={"full"} />
                        <UKPosts mode={"full"} />
                        {/* <TanneryBuildPosts mode={"full"} />
                        <TanneryPosts mode={"full"} /> */}
                    </>
                )
            case "summary":
            default:
                return (
                    <>
                        <USPosts mode={"summary"} />
                        <LineapellePosts mode={"summary"} />
                        <SwitzerlandGermanyPosts mode={"summary"} />
                        <ItalyPosts mode={"summary"} /> 
                        <UKPosts mode={"summary"} />
                        {/* <TanneryBuildPosts mode={"full"} />
                        <TanneryPosts mode={"full"} /> */}
                    </>
                )
        }
    }

    return (
        <>
            <Hero title={"Blog"}></Hero>
            {/* start main */}
            <div className="main_bg">
                <div className="wrap">
                    <div className="main" id={postClass}>
                        <div>
                            <h2>{pageTitle}</h2>
                        </div>
                        {selectTrip(tripTitle)}
                        <div className="clear"></div>
                        <Link to='javascript:history.back()' title='Go back' className='linkArrow'><FontAwesomeIcon icon={faArrowLeft} /> <span>Go back</span></Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default App;