import React from 'react';
import {Link} from 'react-router-dom';

function Salting() {
    const title = 'Home';
    document.title = global.AppName;
    return (
        <>
        <div>test</div>
        </>
    );
}

export default Salting;