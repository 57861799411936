import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import {Link} from 'react-router-dom';
import './index.css';

function Footer() {
    return (
        <footer id="footer">
        <ul className="copyright">
            <li>&copy; <Link to="https://www.cotmarshtannery.co.uk">Cotmarsh  Tannery</Link></li>
        </ul>
        <ul className="icons">
            <li><Link to="https://twitter.com/cotmarshtannery" title="Twitter" className="icon" target="_blank"><FontAwesomeIcon icon={faTwitter} /><span className="label">Twitter</span></Link></li>
            <li><Link to="https://www.facebook.com/cotmarshtannery/" title="Facebook" className="icon" target="_blank"><FontAwesomeIcon icon={faFacebook} /><span className="label">Facebook</span></Link></li>
            <li><Link to="https://www.instagram.com/cotmarshtannery/" title="Instagram" className="icon" target="_blank"><FontAwesomeIcon icon={faInstagram} /><span className="label">Instagram</span></Link></li>
            <li><Link to="/contact-us" title="Contact us" className="icon"><FontAwesomeIcon icon={faEnvelope} /><span className="label">Email</span></Link></li>
        </ul>
        </footer>     
    );
  }
    
  export default Footer;
