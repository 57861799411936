import React, { useState } from "react";
import { NavLink } from 'react-router-dom';
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

import ImageLogo from "../../assets/images/great-cotmarsh-farm-logo.webp";

function Header({ currentPage }) {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [menuItems, setMenuItems] = useState([
        {id: 1, path: '/churchill-fellowship', name: 'Churchill Fellowship'},
        {id: 2, path: '/survey', name: 'Survey'},
        {id: 3, path: '/support', name: 'Support'},
        {id: 4, path: '/contact-us', name: 'Contact Us'},
    ]);

    const toggleMobileMenu = () => {
        setMenuOpen(prevState => !prevState);
    };
    return (
        <>
            <header>
                <div className="wrap">
                    <nav>
                        <div className="logo">
                            <a href="/"><img src={ImageLogo} alt="Cotmarsh Tannery Logo"></img></a>
                        </div>
                        <div className="menu">
                            <NavLink id="open" title="Mobile menu" to="#" className="menuToggle" onClick={toggleMobileMenu} aria-expanded={isMenuOpen} aria-controls="menuItems">
                                <span>Menu</span><FontAwesomeIcon  icon={faBars} />
                            </NavLink>
                            <ul id="menuItems" className={isMenuOpen ? "show" : ""}>
                                <li id="home">
                                    <NavLink to="/" onClick={toggleMobileMenu}>Home</NavLink>
                                </li>
                                {/* <li className="dropdown">
                                    <NavLink to="/the-tannery">The Tannery</NavLink> */}
                                    {/* <ul>
                                        <li><NavLink to="/the-tannery/veg-tanning">Vegetable Tanning</NavLink></li>
                                    </ul> */}
                                {/* </li> */}
                                {
                                    menuItems.map(item => (
                                        <li key={item.id}>
                                            <NavLink to={item.path} onClick={toggleMobileMenu}>
                                                {item.name}
                                            </NavLink>
                                        </li>))
                                }
                                {/* <li><NavLink to="/churchill-fellowship" onClick={toggleMobileMenu} activeClassName="active">Churchill Fellowship</NavLink></li>
                                <li><NavLink to="/survey" onClick={toggleMobileMenu} activeClassName="active">Survey</NavLink></li>
                                <li><NavLink to="/support" onClick={toggleMobileMenu} activeClassName="active">Support</NavLink></li>
                                <li><NavLink to="/contact-us" onClick={toggleMobileMenu} activeClassName="active">Contact Us</NavLink></li> */}
                                <li id="close" onClick={toggleMobileMenu}>
                                    <NavLink to="#">
                                        <FontAwesomeIcon  icon={faXmark} />
                                        <span>Close</span>
                                    </NavLink>
                                </li>
                            </ul>

                        </div>
                        <div className="clear"></div>
                    </nav>
                </div>
                {
                    process.env.NODE_ENV === 'development' && 
                    <div id="devbuild">
                        This is a Dev Build
                    </div>
                }
            </header>
        <noscript>You need to enable JavaScript to run this app.</noscript>
        </>
      
    );
  }

export default Header;    

function Hero({title, subtitle, logo, anchors}) {
    return (
        <>
            {/* start slider */}
            <div className="slider_bg">
                <div className="wrap">
                    <div className="slider" id="home">
                        <div className="slider_text">
                            <h1>{title}</h1>
                            <div>{subtitle}</div>
                            {anchors && anchors.length > 0 && (
                                <div className="slider_btn">
                                    {anchors.map(({id, text}) => 
                                        <a href={'#'+id} key={id} className="button">{text}</a>
                                    )}
                                </div>
                            )}
                            {logo}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export { Hero };