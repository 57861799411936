import { useState } from "react";
import { Link } from 'react-router-dom';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { Hero } from "../../components/header";

const App = () => {
    const pageTitle = 'Newsletter Signup';
    document.title = global.AppName + ' - ' + pageTitle;

    const initialFormData = {
        firstname: '',
        surname: '',
        emailfrom: '',
        subscribe: true,
        captcha: '',
        websiteid: 'CotmarshTannery'
    };
    const [formData, setFormData] = useState(initialFormData);
    const resetFormData = () => {
        setFormData(() => initialFormData);
        setbuttonText(initialFormData.subscribe ? 'Subscribe' : 'Unsubscribe');
      };
     const [isSubmitting, setIsSubmitting] = useState(false);
    
    const [subscribeOptions, setSubscribeOptions] = useState([{key: true, display: 'Subscribe'}, {key: false, display: 'Unsubscribe'}]);
    const [buttonText, setbuttonText] = useState(formData.subscribe ? 'Subscribe' : 'Unsubscribe');

    const [errormessage, setErrormessage] = useState('');
    // Used to see if the form has been submitted when validating 
    const [submitted, setSubmitted] = useState(false);

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleChange = (e) => {
        // set the form data on form field change and validate
        setFormData({ ...formData, [e.target.name]: e.target.value });
        // Set the submit button text to match the select value
        if (e.target.name === 'subscribe') { 
            setbuttonText(e.target.options[e.target.selectedIndex].text);
        }
        validateValues();    
    };

    const validateValues = (formSubmitting = false) => {        
        var valid = false;
        let errormessage = {};

        // if the form has been submitted set as submitted once for future validation
        if (formSubmitting) {
            setSubmitted(true);
        }

        // If form was submitted and field changed after submitted once (so already has errors show) the validate
        if(formSubmitting || submitted) {
            var field = document.getElementById('firstname');
            field.classList.add('error');
            if (field.value.length < 1) {
                errormessage.firstname = "First name is required";
            } else {
                field.classList.remove('error');
            }

            field = document.getElementById('surname');
            field.classList.add('error');
            if (field.value.length < 1) {
                errormessage.surname = "Surname is required";
            } else {
                field.classList.remove('error');
            }
            
            field = document.getElementById('emailfrom');
            field.classList.add('error');
            if (field.value.length < 1) {
                errormessage.emailFrom = "Email address is required";
            } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(field.value)) {
                errormessage.emailFrom = "Invalid email address";
            } else {
                field.classList.remove('error');
            }

            // check if all validation passed (fail safe)
            if (Object.keys(errormessage).length === 0)
            {
                valid = true;
            }
            setErrormessage(errormessage);
        }
        return valid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(validateValues(true)) {
            setIsSubmitting(true); // Disable the button

            const token = await executeRecaptcha("newsletter_form");

            var formSubmitData = formData;
            formSubmitData.captcha = token;

        //   fetch('https://creweallen-function.azurewebsites.net/api/ManageSubscription', { 
        //   fetch('http://localhost:7071/api/ManageSubscription', { 
            fetch(process.env.REACT_APP_API_ENDPOINT + '/ManageSubscription', { 
                method: "POST",
                body: JSON.stringify(formSubmitData),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then(
                (response) => (response.json())
            ).then((response)=> {
                // Hide the form whilst showing submit outcome
                document.getElementById('newsletter-form').style.display = 'none';
                if (response.status === 'success') {
                    // submit sucessful so reset form and set subitted flag to false for full form reset
                    resetFormData()
                    setSubmitted(false);
                    document.getElementById('newsletter-form-message-success').style.display = 'block'
                } else {
                    document.getElementById('newsletter-form-message-fail').style.display = 'block'
                    setFormData({...formData, errormessage: response.message});
                }
            }).catch ((error) => {
                // hide form whilst showing error message
                document.getElementById('newsletter-form').style.display = 'none';
                document.getElementById('newsletter-form-message-fail').style.display = 'block'
                setFormData({...formData, errormessage: error.message});
                // setFormData({...formData, errormessage: "A general error has occured."});
            }).finally(() => {
                setIsSubmitting(false); // Re-enable the button
            });
        }
    };

    const closeMessage = e => {
        // close error / sucess message and reshow the form (either reset or with failed values in fields)
        e.parentNode.style.display='none';
        document.getElementById('newsletter-form').style.display = 'block';
    }

    return (
        <>
            <Hero title={"Newsletter Signup"} subtitle={"Subscribe to say up-to-date"}></Hero>

            {/* start main */}
            <div className="main_bg">
                <div className="wrap">
                    <div className="main" id="newsletter">
                        <h2>Manage your newsletter subscription</h2>

                        <div id="newsletter-form-message-success" style={{display: 'none'}}>
                            <h4>Thank you</h4>
                            <p>
                                Your newsletter subscription status has been updated
                            </p>
                            <input type="submit" className="primary" value="Close" onClick={(event) => closeMessage(event.target)} />
                        </div>                        
                        <div id="newsletter-form-message-fail" className="error" style={{display: 'none'}}>
                            <h4>Error</h4>
                            <p>
                                There appears to be a problem with the newsletter subscriptions, please try again or <Link to={"/contact-us"} title="Contact form">contact us</Link>.
                            </p>
                            {
                                process.env.NODE_ENV === 'development' && 
                                <p>
                                    Error message: {formData.errormessage}
                                </p>
                            }
                            <input type="submit" className="primary" value="Close" onClick={(event) => closeMessage(event.target)} />
                        </div>
                        <form id="newsletter-form" onSubmit={handleSubmit} method="POST" noValidate >
                            <p>
                                Signup to the tannery newsletter to stay up to date with the latest news and updates. We promise not to send you too many emails, 
                                I don't have time to write them, and you can unsubscribe at any time.
                            </p>
                            <div>
                                <div className="col-6 col-12-xsmall">                                    
                                    <label htmlFor="firstname">Firstname<span className="error">*</span></label>
                                    <input type="text" name='firstname' id='firstname' placeholder='First name' value={formData.firstname} onChange={handleChange}/>
                                </div>
                                <div className="col-6 col-12-xsmall">                                    
                                    <label htmlFor="surname">Surname<span className="error">*</span></label>
                                    <input type="text" name='surname' id='surname' placeholder='Surname' value={formData.surname} onChange={handleChange} />
                                </div>
                                <div className="col-6 col-12-xsmall">        
                                    <label htmlFor="emailfrom">Email address<span className="error">*</span></label>
                                    <input type="email" name="emailfrom" id="emailfrom" placeholder="Email" value={formData.emailfrom} onChange={handleChange} />
                                </div>
                                <div className="col-2 col-12-xsmall">
                                <div className="select-container">
                                    <select name="subscribe" id="subscribe" value={formData.subscribe} onChange={handleChange}>
                                        {
                                            subscribeOptions.map(({key, display}) => <option key={key} value={key}>{display}</option>)
                                        }
                                    </select>
                                    <div className="select-caret"></div>
                                </div>
                                </div>
                                <div className="col-12">
                                    <input id="submitbutton" name="submitbutton" type="submit" className="primary" value={buttonText} disabled={isSubmitting} />
                                </div>
                                <div id="ErrorSummary" className="col-12 error">
                                    <ul>
                                        {Object.keys(errormessage).map((key) => (
                                            <li key={key}>{errormessage[key]}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

// wrap the app component with the GoogleReCaptchaProvider
export default () => (
    <GoogleReCaptchaProvider reCaptchaKey="6LeU2n4pAAAAACRrmBrl6o6WbHSw-jRCtVZBiCd2" size="compact" badge="bottomright">
      <App />
    </GoogleReCaptchaProvider>
  );